<template>
  <div class="acronis-config">
    <transition name="fade" mode="out-in">
      <base-loader v-if="!configurator.length" />
      <main-card v-else>
        <div class="acronis-config-config">
          <component
            :is="componentsByType[item.type]"
            v-for="item in configurator"
            :key="item.intname"
            v-bind="item"
            class="acronis-config-config__item"
            @change="onChange(item, $event)"
          />
        </div>
        <template #footerEnd>
          <div class="acronis-config__footer">
            <div class="acronis-config__summary medium-title">
              <!--              <span class="acronis-config__summary-text"> {{ $t('summary') }}: </span>-->
              <!--              <span class="acronis-config__summary-sum">-->
              <!--                {{                  //-->
              <!--                  // (sum > 0 ? '+' : '') +-->
              <!--                  // `${$n(sum, 'currency')} / ${$tc('period.month_c', tariff.period)}`-->
              <!--                }}-->
              <!--              </span>-->
            </div>
            <base-button
              :disabled="!hasChanges"
              :loading="isSending"
              class="acronis-config__order-btn"
              @click="save"
            >
              {{ $t('save') }}
            </base-button>
          </div>
        </template>
      </main-card>
    </transition>
  </div>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard.vue';
import SliderBlock from '@/components/Configurator/components/SliderBlock.vue';
import SelectBlock from '@/components/Configurator/components/SelectBlock.vue';
import { throttle } from 'lodash';
import { AcronisTariff } from '@/models/BillMgr/AcronisTariff';
import { isEqual, isNaN } from 'lodash';
import showErrorModal from '@/mixins/showErrorModal';
import TextBlock from '@/components/Configurator/components/TextBlock';
export default {
  name: 'AcronisConfig',
  components: { MainCard, SliderBlock, SelectBlock },
  mixins: [showErrorModal],
  props: {
    tariff: {
      type: AcronisTariff,
      required: true,
      validator: obj => obj instanceof AcronisTariff,
    },
  },
  data() {
    return {
      sum: 0,
      componentsByType: {
        slider: SliderBlock,
        select: SelectBlock,
        text: TextBlock,
      },
      isSending: false,
      backup: null,
      formData: null,
    };
  },
  computed: {
    configurator() {
      return this.tariff.configurator;
    },
    hasChanges() {
      return !isEqual(this.backup, this.formData);
    },
    tools() {
      return this.$store.state.moduleAcronis.tools;
    },
    profile() {
      return this.$store.state.moduleProfile.profile;
    },
    isEditEnable() {
      return this.tools && this.tools.edit && this.tools.edit.isEnable(this.tariff);
    },
  },
  watch: {
    configurator() {
      if (this.configurator.length) this.init();
    },
  },
  mounted() {
    if (this.configurator.length) this.init();
  },
  methods: {
    init() {
      this.initFormData();
      this.makeBackup();
      this.calcSum();
    },
    makeBackup() {
      this.backup = { ...this.formData };
    },
    initFormData() {
      this.formData = this.configurator.reduce((acc, addon) => {
        const value = !isNaN(parseInt(addon.currentValue))
          ? parseInt(addon.currentValue)
          : addon.currentValue;
        acc[addon.name] = value;
        return acc;
      }, {});
    },
    onChange(item, payload) {
      const { value, sum } = payload;
      item.currentValue = value;
      const intValue = !isNaN(parseInt(value)) ? parseInt(value) : value;
      this.formData[item.name] = intValue;
      if (item.sum !== sum) {
        item.sum = sum;
        throttle(
          () => {
            this.calcSum();
          },
          100,
          { trailing: false }
        )();
      }
    },
    calcSum() {
      this.sum = this.configurator.reduce((sum, i) => {
        sum += parseFloat(i.sum);
        return sum;
      }, 0);
    },
    save() {
      const data = { ...this.formData };
      this.isSending = true;
      this.$store
        .dispatch('moduleAcronis/updateDetail', {
          ...data,
          elid: this.tariff.id,
          account: this.profile.account,
          func: this.tools.edit.func,
        })
        .then(data => {
          if (data.ok) {
            return Promise.all([
              this.$store.dispatch('moduleAcronis/fetchDetail', this.tariff.id),
              this.$store.dispatch('moduleProfile/setProfileInfo'),
            ]).then(() => setTimeout(() => (this.isSending = false), 500));
          }
        })
        .catch(e => {
          this.showError(e);
          this.isSending = false;
        });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "summary": "Итого"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.acronis-config {
  margin-top: 1.5rem;

  +breakpoint(sm-and-up) {
    margin-top: 2.5rem;
  }

  &__footer {
    flexy(flex-end, flex-end);
    flex-direction: column;

    +breakpoint(sm-and-up) {
      flex-direction: row;
      align-items: center;
      padding-bottom: 1rem;
    }
  }

  &__summary {
    flexy(flex-end, center);
    margin-bottom: 1rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 0;
      margin-right: 2.5rem;
    }

    &-text {
      margin-right: 1.5rem;
    }
    &-sum {
      text-transform: lowercase;
    }
  }

  &__order-btn {
    min-width: 160px;
  }

  &-config {
    +breakpoint(sm-and-up) {
      margin-top: 1rem;
    }

    &__item {
      & + & {
        margin-top: 1.5rem;

        +breakpoint(sm-and-up) {
          margin-top: 2.5rem;
        }
      }
    }
  }
}
</style>
